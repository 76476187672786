<template>
  <div class="Map">
    <top :title="storename+'门店'" ></top>
    <baidu-map
      class="map"
      :center="center"
      :zoom="zoom"
      @ready="handler"
      showAddressBar
      :scroll-wheel-zoom="true"
    >
      <bm-marker
        :position="center"
        :dragging="true"
        animation="BMAP_ANIMATION_BOUNCE"
      ></bm-marker>
      <bm-geolocation
        anchor="BMAP_ANCHOR_BOTTOM_RIGHT"
        :showAddressBar="true"
        :autoLocation="true"
      ></bm-geolocation>
    </baidu-map>
    <i></i>
    <div class="nav_btn" @click="nav">点击导航</div>

    <van-popup v-model="show" position="bottom"  >
      <div @click="mapbaidu" class="popup_btn">百度地图</div>
      <div @click="gaodemap" class="popup_btn">高德地图</div>
      <div @click="tengxunmap" class="popup_btn">腾讯地图</div>
    </van-popup>
  </div>
</template>


<script>
import BMap from "BMap";
import BMapSTATUSSUCCESS from "BMAP_STATUS_SUCCESS";
export default {
  data() {
    return {
      center: { lng: 0, lat: 0 },
      zoom: 17,
      storename: "",
      markerPoint: "",
      show: false,
      city: "",
      mylat: 0,
      mylng: 0,
      ishave:true,
      isIOS: true
    };
  },
  methods: {
    weizhi() {
      // //调用百度API获取
      // geolocation.enableSDKLocation()
      var geolocation = new BMap.Geolocation();
      geolocation.getCurrentPosition(function (e) {
        if (this.getStatus() == BMapSTATUSSUCCESS) {
          // 百度 geolocation 的经纬度属性不同，此处是 point.lat 而不是 coords.latitude
          this.mylat = e.point.lat;
          this.mylng = e.point.lng;
        } else {
          alert("failed" + this.getStatus());
        }
      });
    },
    //定位当前城市
    locationed() {
      this.weizhi();
      var objCity = new BMap.LocalCity();
      objCity.get((result) => {
        this.city = result.name;
      });
    },
    addZoom(level) {
      this.zoom = level;
    },
    handler() {
      this.center.lng = this.$route.query.lat;
      this.center.lat = this.$route.query.lng;
      this.zoom = 17;
    },
    nav() {
      this.show = true;
    },
    mapbaidu() {
      window.location.href=`http://api.map.baidu.com/geocoder?location=${this.$route.query.lng},${this.$route.query.lat}&coord_type=gcj02&output=html&src=webapp.baidu.openAPIdemo`
    },
    gaodemap() {
       window.location.href= `//uri.amap.com/marker?position=${this.$route.query.lat},${this.$route.query.lng}&name=${this.storename}&src=https://www.hyb1010.com&coordinate=gaode&callnative=1`
    },
    tengxunmap(){
      window.location.href=`https://apis.map.qq.com/uri/v1/marker?marker=coord:${this.$route.query.lng},${this.$route.query.lat};title:${this.storename};addr:${sessionStorage.getItem('storeAddress')}&referer=myapp&output=html`
    }
  },
  mounted() {
    this.storename = this.$route.query.storename;
    this.locationed();
  },
};
</script>

<style lang="scss" scoped>
.nav_btn{
  width: vw(680);
  background: #019CF4;
  color: #fff;
  border-radius: vw(50);
  height: vw(40);
  padding: vw(26) 0;
  text-align: center;
  position: fixed;
  bottom: vw(22);
  left: vw(32);
}
.popup_btn{
  font-size: vw(28);
  color: #333;
  font-weight: 600;
  text-align: center;
  border-bottom: 1px solid #f4f4f4;
  padding: vw(28);
}
.Map {
  width: 100%;
  height: 100%;
  .map {
    width: 100%;
    height: 94vh;
  }
  p {
    font-size: vw(40);
    margin-top: vw(30);
    text-align: center;
    color:gray;
  }
  .nav {
    width: 100%;
    height: 100%;
    ul > li {
      margin-top: vw(30);
      width: 100%;
      height: vw(100);
      text-align: center;
      font-size: vw(35);
      font-weight: bold;
    }
    button {
      width: 80%;
      height: vw(100);
      margin-left: 10%;
      background-color: $indexcolor;
      border: none;
      border-radius: vw(50);
      color: white;
      font-size: vw(30);
    }
  }
}
</style>
